import { compose, lazy, map, mount, redirect, route, withView } from "navi";
import * as React from "react";
import { NotFoundBoundary, View } from "react-navi";

export type IContext = {
  currentUser;
  authService;
  navigation;
};

const renderNotFound: React.FC<any> = () => <h1>404 - Not Found</h1>;

export default compose(
  withView(
    <NotFoundBoundary render={renderNotFound}>
      <View />
    </NotFoundBoundary>
  ),

  mount({
    "/login": map(async (req, context: IContext) =>
      context.currentUser
        ? redirect(
            req.params.redirectTo
              ? decodeURIComponent(req.params.redirectTo)
              : "/osl"
          )
        : route({
            title: "Login",
            getView: async (_req, context: IContext) => {
              const Login = await import("../pages/Login");
              return <Login.default authService={context.authService} />;
            }
          })
    ),

    "*": map(async (req, context: IContext) => {
      return !context.currentUser
        ? redirect(
            `/login/?redirectTo=${encodeURIComponent(req.originalUrl)}`,
            { exact: false }
          )
        : mount({
            "/:team": lazy(() => import("./teamRoutes"))
          });
    }),

    "/": redirect("/login")
  })
);
